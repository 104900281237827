.familyMembersContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.membersContainer {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: white;
}

.member {
  width: fit-content;
  padding: 12px;
  display: flex;
  column-gap: 12px;
  border: 1px solid lightgray;
  border-radius: 8px;
  align-items: center;
  background-color: white;
}

.icons {
  cursor: pointer;
}

.addFieldBtn {
  cursor: pointer;
  color: cornflowerblue;
  font-weight: 600;
  font-size: 14px;
}

.addFieldBtn:hover {
  text-decoration: underline;
}
