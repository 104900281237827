.categoryContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px 0;
}

.fieldContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.infieldsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.textSection {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.fieldHeader {
  font-weight: 600;
  font-size: 16px;
}

.infieldHeader {
  font-size: 14px;
  font-weight: 600;
}

textarea {
  field-sizing: content;
  padding: 12px;
  resize: none;
}
