.top-nav {
    background-color: #1f1b17;
    color: #ecf0f1;
}

.top-nav div a {
    color: #ecf0f1 !important;
}

.bg-black {
    background-color: #1f1b17;
}

.dropdown .dropdown-menu {
    background-color: #1f1b17;
}

.dropdown-menu a {
    border: 1px solid #1f1b17;
    color: #61dafb;
}

.checkbox-custom {
    float: left;
    margin-right: 10px;
}

.white-bg {
    background-color: #fff;
}

.accordion {
    --bs-accordion-active-bg: #ceae4e !important;
    --bs-accordion-bg: #d2be7d !important;
}

.accordion-item {
    background-color: #f1e1cf !important;
}

.main-heading {
    font-family: system-ui;
    font-weight: 600;
    font-size: 1.5rem;
}

.sub-heading {
    font-family: system-ui;
    font-weight: 600;
    font-size: 1rem;
}

.card-section {
    background-color: #f0dbc2 !important;
    padding: 3px 2px 2px 3px !important;
}

.border-btm {
    border-bottom: 1px solid #ececec;
}

.member-section {
    background-color: #f0dbc2;
    padding: 3px 2px 2px 3px !important;
    border: 1px solid #996A3AFF;
}

.row-pad {
    padding: 10px;
}

.border-full {
    border: 1px solid #996a3a;
}

.member-head {
    justify-content: right;
    display: flex;
    padding: 0 0 3px 0;
    border-bottom: 1px solid #996a3a;
    background-color: #e7b989;
}
