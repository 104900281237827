.container {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    background: white;
    padding: 15px;
}

.container :global(.rhap_container) {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    width: 100%;
}

.container :global(.rhap_progress-filled),
.container :global(.rhap_progress-indicator) {
    background-color: var(--primary-color, #4caf50);
}

.container :global(.rhap_controls-section .rhap_button-clear),
.container :global(.rhap_volume-button),
.container :global(.rhap_main-controls-button),
.container :global(.rhap_time) {
    color: var(--primary-color, #4caf50);
}

.container :global(.rhap_progress-bar) {
    background-color: #e0e0e0;
}

.container :global(.rhap_download-progress) {
    background-color: #b3b3b3;
}

.downloadWrapper {
    position: static;
    padding: 0 25px;
    margin-left: auto;
    z-index: 10;
}

.badge {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #4caf50;
    color: white;
    font-size: 0.6rem;
    font-weight: bold;
    padding: 1px 10px;
    border-top-left-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.badge::before {
    content: '';
    position: absolute;
    bottom: -6px;
    right: -6px;
    width: 12px;
    height: 8px;
    background: inherit;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    z-index: -1;
}

@media (max-width: 640px) {
    .container {
        flex-direction: column;
        gap: 15px;
    }

    .downloadWrapper {
        margin-left: 0;
        padding: 10px 0;
    }
}