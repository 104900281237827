body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/index.css */

html, body, #root, .App, .container-fluid {
  height: 100%;
  font-family: sans-serif;
}

.Sidebar {
  height: 100%;
  padding: 1rem 0 0 0;
  background-color: #1f1b17;
  border-right: 1px solid #000;
  color: #ecf0f1;
}

.Sidebar div a {
  color: #ecf0f1 !important;
  font-weight: 700;
}

.DashboardContent {
  padding: 1rem;
}

.Navbar {
  margin-bottom: 1rem;
}

.full-height {
  height: 100%;
}

.nav-item-nw {
  padding: 5px;
  border: #282c34 1px solid;
}

.page-head {
  font-family: system-ui;
  padding: 1rem 0 0 1rem;
  background-color: #e2ccb0;
}
.page-head span {
  font-weight: 600;
}

.btn-align {
  align-content: end;
}

.cob-row {
  border: 1px solid #664d03;
  margin-bottom: 1rem;
}