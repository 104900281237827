
.login-row {
    padding: 5rem 1rem 0 1rem
}
.card-custom {
    border: none !important;
    background-color: inherit !important;
}

.card-nw {
    width: 80% !important;
    padding-left: 10% !important;
}

.login-btn-nw {
    width: 100% !important;
    background-color: #1f1b17 !important;
    border-color: #000 !important;
}
.card-title-nw {
    font-size: 26px !important;
    font-weight: 600 !important;
    margin-top: 3.5rem !important;
    text-align: center !important;
}

.right-border {
    border-right: 1px !important;
}