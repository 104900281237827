.reportContainer {
  width: 100%;
  /* height: 100%; */
}

.reportTabHeader {
  border-bottom: 1px solid lightgray;
}

.reportChildren {
  width: 100%;
  height: 100%;
  padding: 12px;
}
